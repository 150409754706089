import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../../components/Seo";
import {Link} from "gatsby";

import rotate1 from "../../images/pourigins/color1.png";
import rotate2 from "../../images/pourigins/color2.png";
import rotate3 from "../../images/pourigins/color3.png";
import rotate4 from "../../images/pourigins/color4.png";
import all from "../../images/pourigins/all-logos.png";
import box from "../../images/pourigins/box1.png";
import colors from "../../images/pourigins/colors.png";
import group from "../../images/pourigins/all1.png";
import pattern from "../../images/pourigins/pattern.png";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
            <div
                    className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                    ref={domRef}
            >
                {props.children}
            </div>
    );
}

class Pourigins extends React.Component {
    constructor(props) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.state = {
            currentImage: 0,
            images: [
                rotate1, rotate2, rotate3, rotate4
            ]
        };
    }

    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        } else {
            this.setState({
                currentImage: 0
            });
        }
        return this.currentImage;
    }

    componentDidMount() {
        setInterval(this.switchImage, 1000);
    }

    render() {
        return (
                <Layout pageName="pourigins">
                    <Seo
                            title={'Pourigins'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-36">
                                <h1 className="font-serif text-left text-4xl sm:text-9xl leading-none sm:-mt-4">Pourigins</h1>
                                <div className="sm:flex justify-between mt-16 sm:mt-24">
                                    <div className="flex">
                                        <div className="">
                                            <h3 className="font-mono italic text-sm featured-color">Role</h3>
                                            <ul className="w-auto mt-2 inline-flex flex-col items-start">
                                                <li className="border rounded-full py-1 px-4 w-auto font-mono italic text-sm">Brand Identity</li>
                                            </ul>
                                        </div>
                                        <div className="ml-24">
                                            <h3 className="font-mono italic text-sm featured-color">Date</h3>
                                            <h4 className="mt-2">2019</h4>
                                        </div>
                                    </div>
                                    <div className="mt-8 sm:mt-0 sm:w-2/5">
                                        <p className="leading-normal">Pourigins is an e-commerce platform that promotes direct-to-consumer sales of craft alcoholic beverages by emphasizing products’ origin and uniqueness. They came to me in need of a brand for their startup and we created a logo set that encompasses the four different drink types they offer.</p>
                                    </div>
                                </div>
                                <FadeInSection>
                                    <div className="mt-16">
                                        <img
                                                src={this.state.images[this.state.currentImage]}
                                                alt="project images"
                                        />
                                    </div>
                                </FadeInSection>
                            </div>
                            <div className="extra-large-wrapper mt-16">
                                <FadeInSection>
                                    <img src={all} alt="logos" className=""/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={colors} alt="" className="mt-16"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={group} alt="" className="mt-16"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={pattern} alt="" className="mt-16"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={box} alt="" className="mt-16"/>
                                </FadeInSection>
                            </div>
                        </section>
                        <section>
                            <FadeInSection>
                                <div className="extra-large-wrapper text-center relative mt-16 sm:mt-32 sm:mb-16">
                                    <Link to="/work/datanyze" className="text-3xl sm:text-6xl font-serif border-b-2 border-white hover:border-black">
                                        next project — Datanyze
                                    </Link>
                                </div>
                            </FadeInSection>
                        </section>
                    </Container>
                </Layout>
        );
    }
}

export default Pourigins;
